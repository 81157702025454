import { graphql, Link } from "gatsby"
import React from "react"
// import { makeBlogPath } from "../utils"
// import dateformat from "dateformat"

import SEO from '../components/seo'
import Layout from '../components/Layout'
//import { rhythm } from '../utils/typography'
import { Card, Row, Col, Button } from 'react-bootstrap'
// import { SlugLink } from '../components/utils'
const baseuri = "http://63.141.235.90:8091"
class PostTag extends React.Component {
  renderTags(tags) {
    tags.map((item) => {
      //console.log(item)
      return <Button variant="outline-secondary">{item}</Button>
    })
  }
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description
    const domainName = data.site.siteMetadata.domainName
    const posts = data.privateGraphql.postbytag
    const { currentPage, numPages } = this.props.pageContext
    // const isFirst = currentPage === 1
    // const isLast = currentPage === numPages
    // const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    // const nextPage = (currentPage + 1).toString()
    //console.log(currentPage, numPages, prevPage, nextPage, limit, skip, posts)
    // const tagHeader = `${posts.length} post${
    //   posts.length === 1 ? "" : "s"
    //   } "${posts[0].categoryName}"`
    // if (location != null) {
    //   if (location.href != null && location.href.endsWith('/')) {
    //     window.location.href = window.location.href.substring(0, window.location.href.length - 1);
    //   }
    // }
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`${siteTitle}`}
          description={`${siteDescription}`}
          canonical={`https://${domainName}`}
        />
        <h1 className="w-grid__columns w-grid__columns--one"></h1>
        <div className="w-grid__columns w-grid__columns--three">
          {posts.map(node => {
            const title = node.title || node.slugtitle
            return (
              <div className="w-card" key={node.slugtitle}>
                <article className="w-post-card ">
                  <div className="w-post-card__cover w-post-card__cover--with-image">
                    <Link className="w-post-card__link" tabIndex="-1" to={node.slugtitle}>
                      <figure className="w-post-card__figure">
                        <img src={baseuri + node.image} alt={title} title={title} className="w-post-card__image" />
                      </figure>
                    </Link>
                  </div>
                  <div className="w-post-card__blurb">
                    <Link className="w-post-card__link" to={node.slugtitle}>
                      <h2 className="w-post-card__headline--with-image">
                        {title}
                      </h2>
                    </Link>
                    <div className="w-authors__card">
                      <div className="w-author__image--row">
                        <div className="w-author__image--row-item">
                          <img className="w-author__image w-author__image--small" src={baseuri + node.profileImage} alt="favicon" />
                        </div>
                      </div>
                      <div>
                        <span className="w-author__name">
                          <Link to={`/authors/${node.profileSlugName}`}>{node.profileName}</Link>
                        </span>
                        <span className="w-author__name">
                          {node.categoryName !== null ? <Link to={`/category/${node.slugCategoryName}`}>{node.categoryName}</Link> : "Tin Tức Bóng Đá"}
                        </span>
                        <div className="w-author__published">
                          <time>{node.publishDate}</time>
                        </div>
                      </div>
                    </div>
                    <div className="w-post-card__desc">
                      <Link className="w-post-card__link" tabIndex="-1" to={node.slugtitle}>
                        <p className="w-post-card__subhead" dangerouslySetInnerHTML={{ __html: node.short }} />
                      </Link>
                    </div>
                  </div>
                </article>
              </div>
            )
          })}
        </div>
        {/* <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            listStyle: 'none',
            padding: 0,
          }}
          className="pagi"
        >
          {!isFirst && (
            <li>
              <Link to={`/page/${prevPage}`} rel="prev">
                <Button>
                  ← Previous Page
                                </Button>
              </Link>
            </li>
          )}
          {Array.from({ length: numPages }, (_, i) => {
            if (i < currentPage + 3 && i > currentPage - 3) {
              return (
                <li
                  key={`pagination-number${i + 1}`}
                >
                  <Link
                    to={`/${i === 0 ? '' : 'page/' + (i + 1)}`}
                    style={{
                      textDecoration: 'none',
                      color: i + 1 === currentPage ? '#ffffff' : '',
                      background: i + 1 === currentPage ? '#007acc' : '',
                    }}
                  >
                    <Button>
                      {i + 1}
                    </Button>
                  </Link>
                </li>
              )
            }
          })}
          {!isLast && (
            <li>
              <Link to={`/page/${nextPage}`} rel="next">
                <Button>
                  Next Page →
                            </Button>
              </Link>
            </li>
          )}
        </ul> */}
      </Layout >
      // <div>
      //   <h1>My Gatsby Blog</h1>
      //   <p>
      //     <a href="https://www.gatsbyjs.org/packages/gatsby-source-graphql/">
      //       Using gatsby-source-graphql
      // </a>
      //   </p>
      //   {data.privateGraphql.news.map((blog, i) => (
      //     <Link key={i} to={`/${blog.slugtitle}.html`}>
      //       <h2>
      //         {blog.title}
      //       </h2>
      //     </Link>
      //   ))}
      // </div>
    )
  }
}
export default PostTag
export const query = graphql`
query ($tag: String,$limit: Int = 15, $skip: Int = 0) {
  site {
    siteMetadata {
      title
      domainName
      description
    }
  }
  privateGraphql {
    postbytag(
      slugtitle: $tag
      limit: $limit 
      skip: $skip
      ) {
        id
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
        profileImage
    }
  }
}
`
